import React, { useState } from 'react';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import pipe from '../../assets/pipe.jpg';
import { pipeWeight } from '../../utils/calculations';
import { RHFTextField } from '../../../../components/hook-form';
import BasicForm from '../BasicForm';

export default function PipeForm() {
	const [weight, setWeight] = useState(0);

	const schema = Yup.object().shape({});

	const defaultValues = {
		material: 'steel',
		len: 1,
		dia: 1,
		thickness: 1,
		quantity: 1,
		density: 0
	};

	const onSubmit = (data) => {
		setWeight(pipeWeight(data));
	};

	return (
		<div>
			<BasicForm onSubmit={onSubmit} defaultValues={defaultValues} schema={schema} weight={weight} >
				<Box sx={{ marginBottom: 4, marginTop: 4, display: 'flex', justifyContent: 'center' }}>
					<img height={'100px'} src={pipe} alt="pipe" />
				</Box>
				<RHFTextField name="len" label="Length (mm)" type="number" />
				<RHFTextField name="dia" label="Outside Diameter (mm)" type="number" />
				<RHFTextField name="thickness" label="Thickness (mm)" type="number" />
			</BasicForm>
		</div>
	);
};
