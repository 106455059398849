import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, DialogActions, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconButtonAnimate } from '../../components/animate';
import Iconify from '../../components/Iconify';
import MetalWeightCalculator from './MetalWeightCalculator';

export default function MetalWeightCalculatorDialog({open, setOpen}) {
	const [material, setMaterial] = useState('');
	const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const handleMaterial = (e) => {
		setMaterial(e.target.value);
	};

	return (
		<Dialog
			open={open}
			maxWidth={'xs'}
			fullWidth
			fullScreen={fullScreen}
			sx={{
				'& .MuiDialog-paper': {
					margin: fullScreen ? 0 : '16px'
				},
			}}>
			<DialogTitle
				sx={{
					position: 'sticky',
					top: 0,
					backgroundColor: theme.palette.background.paper,
					zIndex: 1,
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					padding: 2
				}}
			>
				محاسبه وزن
				<IconButtonAnimate  color="error" onClick={() => setOpen(false)}>
					<Iconify icon="eva:close-fill" width={24} height={24} />
				</IconButtonAnimate>
			</DialogTitle>
			<DialogContent sx={{paddingBottom: 0}}>
				<MetalWeightCalculator />
			</DialogContent>
		</Dialog>
	);
};
