import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { MenuItem, Dialog, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/Iconify';
import MetalWeightCalculatorDialog from '../../../apps/calculator/MetalWeightCalculatorDialog';


export default function ToolsPopover() {

    const [open, setOpen] = useState(null);
    const [openCalculator, setOpenCalculator] = useState(false);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <>
            <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
                <Iconify icon="tabler:tools" width={20} height={20} />
            </IconButtonAnimate>
            <MetalWeightCalculatorDialog open={openCalculator} setOpen={setOpenCalculator} />
            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                <MenuItem onClick={() => setOpenCalculator(true)} sx={{ m: 1 }}>
                    محاسبه وزن
                </MenuItem>
            </MenuPopover>
        </>
    );
}
