import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Grid, Box, Button } from '@mui/material';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import { densities } from '../utils/data';

export default function BasicForm({ onSubmit, defaultValues, schema, weight, children }) {

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: { ...defaultValues, density: 7850 },
    });

    const {
        setValue,
        handleSubmit
    } = methods;

    const handleChangeMaterial = (e) => {
        setValue('density', densities[e.target.value]);
        setValue('material', e.target.value);
    }

    return (
        <div>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} id="metal-weight-calculator-form">
                <Grid container spacing={3} sx={{ marginTop: 2 }}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'grid',
                                columnGap: 10,
                                rowGap: 3,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)' },
                            }}
                        >
                            <RHFSelect name="material" label="Material" placeholder="material" onChange={handleChangeMaterial}>
                                {Object.keys(densities).map((key) => (
                                    <option key={key} value={key}>
                                        {key}
                                    </option>
                                ))}
                            </RHFSelect>
                            <RHFTextField
                                label="Density (kg/sq. m)"
                                name="density"
                                disabled
                            />

                            {children}

                            <RHFTextField
                                label="Quantity (pcs)"
                                name="quantity"
                            />
                            <Box
                                container
                                sx={{
                                    position: 'sticky',
                                    bottom: 0,
                                    backgroundColor: 'white',
                                    zIndex: 1,
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    padding: 4,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                {weight > 0 && (
                                    <div>
                                        <p>وزن {weight} کیلوگرم</p>
                                    </div>
                                )}
                                <Button variant="contained" type="submit">
                                    محاسبه وزن
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </FormProvider>
        </div>
    );
};
