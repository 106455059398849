import React, { useState } from 'react';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import roundBar from '../../assets/round-bar.gif';
import { roundBarWeight } from '../../utils/calculations';
import { RHFTextField } from '../../../../components/hook-form';
import BasicForm from '../BasicForm';

export default function RoundBarForm() {
	const [weight, setWeight] = useState(0);

	const schema = Yup.object().shape({});

	const defaultValues = {
		material: 'steel',
		len: 1,
		dia: 1,
		quantity: 1,
		density: 0
	};

	const onSubmit = (data) => {
		setWeight(roundBarWeight(data));
	};

	return (
		<div>
			<BasicForm onSubmit={onSubmit} defaultValues={defaultValues} schema={schema} weight={weight} >
				<Box sx={{ marginBottom: 4, marginTop: 4, display: 'flex', justifyContent: 'center' }}>
					<img height={'100px'} src={roundBar} alt="roundBar" />
				</Box>
				<RHFTextField name="len" label="Length (mm)" type="number" />
				<RHFTextField name="dia" label="Diameter (mm)" type="number" />
			</BasicForm>
		</div>
	);
};
