import React, { useState } from 'react';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import angle from '../../assets/angle.gif';
import { angleWeight } from '../../utils/calculations';
import { RHFTextField } from '../../../../components/hook-form';
import BasicForm from '../BasicForm';

export default function AngleForm() {
	const [weight, setWeight] = useState(0);

	const angleSchema = Yup.object().shape({});

	const defaultValues = {
		material: 'steel',
		len: 1,
		height: 1,
		width: 1,
		thickness: 1,
		quantity: 1,
		density: 0
	};

	const onSubmit = (data) => {
		setWeight(angleWeight(data));
	};

	return (
		<div>
			<BasicForm onSubmit={onSubmit} defaultValues={defaultValues} schema={angleSchema} weight={weight} >
				<Box sx={{ marginBottom: 4, marginTop: 4, display: 'flex', justifyContent: 'center' }}>
					<img height={'100px'} src={angle} alt="plate" />
				</Box>
				<RHFTextField name="len" label="Length (mm)" type="number" />
				<RHFTextField name="height" label="Height (mm)" type="number" />
				<RHFTextField name="width" label="Width (mm)" type="number" />
				<RHFTextField name="thickness" label="Thickness (mm)" type="number" />
			</BasicForm>
		</div>
	);
};
