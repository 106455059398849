import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import { materials } from './utils/data'
import AngleForm from './forms/angle-form/AngleForm';
import BeamForm from './forms/beam-form/BeamForm';
import ChannelForm from './forms/channel-form/ChannelForm';
import PipeForm from './forms/pipe-form/PipeForm';
import PlateForm from './forms/plate-form/PlateForm';
import RoundBarForm from './forms/roundBar-form/RoundBarForm';
import HexagonForm from './forms/hexagon-form/HexagonForm';

export default function MetalWeightCalculator() {

	const [material, setMaterial] = useState('plate');

	const handleMaterial = (e) => {
		setMaterial(e.target.value);
	};

	return (
		<div>
			<Box sx={{
				position: 'sticky',
				top: 0,
				backgroundColor: 'white',
				zIndex: 2,
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}>
				<FormControl sx={{marginTop: 1, width: '100%' }}>
					<InputLabel id="demo-multiple-name-label">shape</InputLabel>
					<Select onChange={handleMaterial} value={material} name="shape" label="shape" labelId="demo-simple-select-label">
						{Object.keys(materials).map((m) => (
							<MenuItem
								key={m} value={m}
							>
								{materials[m]}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>

			{material === 'angle' && <AngleForm />}
			{material === 'plate' && <PlateForm />}
			{material === 'channel' && <ChannelForm />}
			{material === 'beam' && <BeamForm />}
			{material === 'roundBar' && <RoundBarForm />}
			{material === 'pipe' && <PipeForm />}
			{material === 'hexagon' && <HexagonForm />}
		</div>
	);
};
